const CSS_CLASSES = {
	MENU_ITEM: 'nav-item',
	MENU_ITEM_WITH_DOTS: 'is-dots',
	MENU_DROPDOWN: 'dropdown-menu',
	CONTAINER: 'navbar-collapse'
};

function mainMenuResizer(element)
{
	const container = document.querySelector(`.${CSS_CLASSES.CONTAINER}`)

	container.style.overflow = 'hidden';
	const menuRect = container.getBoundingClientRect();

	const dots = element.querySelector(`.${CSS_CLASSES.MENU_ITEM_WITH_DOTS}`);
	dots.style.display = 'block';
	const dotsDropdown = dots.querySelector(`.${CSS_CLASSES.MENU_DROPDOWN}`) || document.createElement('div');
	const dotsRect = dots.getBoundingClientRect();
	while(dotsDropdown.firstChild)
		dotsDropdown.removeChild(dotsDropdown.lastChild);

	const items = Array.prototype.slice.call(element.children, 0)
		.filter(item => !item.classList.contains(CSS_CLASSES.MENU_ITEM_WITH_DOTS));

	let needDots;

	for (const i in items)
	{
		const item = items[i];
		item.style.removeProperty('display');

		const itemRect = item.getBoundingClientRect();
		needDots = i < items.length - 1;

		if (
			(needDots && itemRect.right + dotsRect.width > menuRect.right) ||
			(!needDots && itemRect.right > menuRect.right)
		)
		{
			items
				.slice(i)
				.forEach(item => {
					const cloneItem = item.querySelector('.nav-link').cloneNode(true);
					cloneItem.classList.remove('nav-link')
					cloneItem.classList.add('dropdown-item');

					const dropdownItem = document.createElement('li');
					dropdownItem.appendChild(cloneItem);

					dotsDropdown.appendChild(dropdownItem);

					item.style.display = 'none';
				});

			if (i < items.length)
				needDots = true;

			break;
		}
	}

	if (!needDots)
		dots.style.display = 'none';

	container.style.overflow = '';
}

export { mainMenuResizer }